import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VigliettiIndustries from "../components/SiteLogos/viglietti-industries"
import "../styles/equipment.scss"
import Gallery from "../components/Gallery"

function Stainless() {
  const images = Array.from({ length: 28 }, (_, index) => {
    return {
      url: `/images/MANUFACTURING_GALLERY/VLE_MANUFACTURING_GALLERY_1200x1200_${
        index + 1
      }.jpg`,
    }
  })

  return (
    <Layout
      pageInfo={{ pageName: "viglietti-industries" }}
      logo={<VigliettiIndustries />}
    >
      <SEO
        title="Viglietti Industries"
        description="Viglietti Industries imports and maintains MORI-TEM, Spadoni, Quinti, Cipriani, Ghidi, Comac machines, becoming the largest brewery supplier in SA."
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0 m-0">
            <StaticImage
              style={{ height: "450px", margin: "auto" }}
              src="../images/VLE-MANUFACTURING-1920x800.jpg"
              alt="Stainless Steel Manufacturing"
              placeholder="blurred"
              layout="fullWidth"
            />
          
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-4">
          <div className="col-0 col-lg-2"></div>
          <div className="col-12 col-lg-8 pt-4 m-0">
            <p className="mb-4">
              <span className="myDropcap">W</span>ith our experienced team of welders and fitters, we specialize in manufacturing stainless steel products based on custom requests and designs. Our primary focus is on beer fermenter unitanks ranging from 150 Liters to 1200 Liters, featuring double insulation, pressure capabilities, yeast management systems, and more.
            </p>
            <p className="mb-4">
                We also do a large range of custom tanks for any products. We make mixing tanks with variable speed. Complete CIP (Clean-In-Place) units, gravity filters and more. 
            </p>
           
            <Gallery galleryImages={images} />
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Stainless
